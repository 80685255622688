<script setup lang="ts">
import type { AppSwitcherOption, AppSwitcherProps } from './types';

defineProps<AppSwitcherProps>();

const model = defineModel<string | number>();

function updateModelValue(value: AppSwitcherOption) {
  model.value = value.name;
}
</script>

<template>
  <div class="app-switcher">
    <button
      class="app-switcher__button"
      :class="{ active: optionLeft.name === model }"
      @click="updateModelValue(optionLeft)">
      {{ optionLeft.label }}
      <slot name="left" />
    </button>
    <button
      class="app-switcher__button"
      :class="{ active: optionRight.name === model }"
      @click="updateModelValue(optionRight)">
      {{ optionRight.label }}
      <slot name="right" />
    </button>
  </div>
</template>

<style lang="scss" src="./style.scss" />
